<template>
  <transition
    enter-active-class="transition ease-out duration-300 transform"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="open"
      class="fixed inset-0 z-50 grid min-h-screen grid-cols-1 grid-rows-1 overflow-y-auto"
    >
      <div
        class="col-span-1 col-start-1 row-span-1 row-start-1 justify-stretch self-stretch bg-grey/40"
        @click="open = false"
      />

      <div
        class="relative col-span-1 col-start-1 row-span-1 row-start-1 max-h-[calc(100dvh-2rem)] w-full transform self-center justify-self-center overflow-auto bg-white shadow-xl transition-all md:rounded-md"
        :class="[dialogClasses, {
          'sm:max-w-sm': size === 'small',
          'sm:max-w-screen-lg': size === 'large',
          'sm:max-w-screen-sm': size=== 'default',
        }]"
        role="dialog"
        aria-visible="true"
        aria-labelledby="visible-headline"
      >
        <div
          v-if="$slots.header || title"
          class="sticky top-0 z-50"
          :class="[headerClasses, {
            'bg-transparent !pb-0 !text-left': size === 'small',
          }]"
        >
          <slot name="header">
            <h3
              class="text-lg text-primary"
              v-html="title"
            />
          </slot>

          <FontAwesomeIcon
            :icon="['fal', 'times']"
            class="absolute right-4 top-3 cursor-pointer rounded p-2 px-2.5 transition-colors hover:bg-grey/10 active:bg-grey/20"
            @click="open = false"
          />
        </div>

        <div
          :class="[contentClasses, {
            'px-6': size === 'small',
          }]"
        >
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  contentClasses?: string
  dialogClasses?: string
  headerClasses?: string
  title?: string
  size?: 'default' | 'small' | 'large'
}>(), {
  contentClasses: 'px-10 py-5',
  dialogClasses: '',
  headerClasses: 'text-primary text-xl font-bold md:rounded-t-xl px-6 py-4 text-center bg-primary-lightest rounded-b-none',
  size: 'default',
  title: undefined,
});

const emit = defineEmits<{
  'close': [],
}>();

const open = defineModel<boolean>('open', {
  required: true,
});

watch(open, (open) => {
  if (!open) {
    emit('close');
  }
});
</script>
